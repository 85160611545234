import {
  FC, useContext, useEffect,
} from 'react';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from '@mui/material';
import {
  Theme,
  useConfig,
  MixpanelProvider,
  ServiceName,
  TenantType,
  toTitleCase,
  getName,
  useDatadog,
} from '@divisionsinc/cc-shared';
import { en, fr } from 'translations';
import { ErrorBoundary } from 'components/shared/ErrorBoundary';
import { LocaleContext, LocaleContextType } from 'LocaleContext';
import { BrowserRouter } from 'react-router-dom';
import useAppState from 'store/hooks';
import { Routes } from './Routes';
import 'App.css';

type Value = {
  [key: string]: string
};

type Messages = {
  [key: string]: Value
};

const messages: Messages = {
  en,
  fr,
};

const App: FC = () => {
  const [locale] = useContext(LocaleContext) as LocaleContextType;
  const { userProfile } = useAppState();
  const config = useConfig();
  const { datadogLogs, datadogRum } = useDatadog();

  useEffect(() => {
    datadogRum.onReady(() => {
      datadogRum.setUser({
        id: userProfile?.sub,
        name: getName({ firstName: userProfile?.firstName, lastName: userProfile?.lastName }),
      });
    });
    datadogLogs.onReady(() => {
      datadogLogs.setUser({
        id: userProfile?.sub,
        name: getName({ firstName: userProfile?.firstName, lastName: userProfile?.lastName }),
      });
    });
  }, [userProfile?.sub]);

  useEffect(() => {
    window.addEventListener('storage', (event) => {
      if (event.key === 'logoutEvent') {
        window.location.href = `${window.location.origin}/login`;
      }
    });
  }, []);

  return (
    <IntlProvider locale={locale as string} messages={messages[locale]}>
      <ThemeProvider theme={Theme}>
        <BrowserRouter>
          <ErrorBoundary>
            <MixpanelProvider
              tenantType={userProfile?.tenant?.name === TenantType.DMG
                ? TenantType.DMG : toTitleCase(userProfile?.tenant?.tenantType) as TenantType}
              serviceName={ServiceName.USER_MANAGEMENT}
              enableTracking={process.env.REACT_APP_ENABLE_MIXPANEL === 'true'}
              token={config.mixpanelKey}
            >
              <Routes />
            </MixpanelProvider>
          </ErrorBoundary>
        </BrowserRouter>
      </ThemeProvider>
    </IntlProvider>
  );
};

export default App;
