import { defineMessages } from 'react-intl';

export default defineMessages({
  siteTitle: {
    id: 'siteTitle',
    defaultMessage: 'DMG Control Center',
  },
  createdOn: {
    id: 'createdOn',
    defaultMessage: 'Created On',
  },
  createdBy: {
    id: 'createdBy',
    defaultMessage: 'by',
  },
  lastEdited: {
    id: 'lastEdited',
    defaultMessage: 'Last Edited',
  },
  userType: {
    id: 'userType',
    defaultMessage: 'Type',
  },
  organization: {
    id: 'organization',
    defaultMessage: 'Organization',
  },
  organizationType: {
    id: 'organizationType',
    defaultMessage: 'Organization Type',
  },
  numberOfUsersAssigned: {
    id: 'numberOfUsersAssigned',
    defaultMessage: 'No. of Users assigned',
  },
  department: {
    id: 'department',
    defaultMessage: 'Department',
  },
  cancelCta: {
    id: 'cancelCta',
    defaultMessage: 'No',
  },
  emailId: {
    id: 'emailId',
    defaultMessage: 'Email ID',
  },
  name: {
    id: 'name',
    defaultMessage: 'Name',
  },
  status: {
    id: 'status',
    defaultMessage: 'Status',
  },
  role: {
    id: 'role',
    defaultMessage: 'Role',
  },
  manager: {
    id: 'manager',
    defaultMessage: 'Manager',
  },
  managerHelperText: {
    id: 'managerHelperText',
    defaultMessage: 'Select a manager from the list',
  },
  searchManager: {
    id: 'searchManager',
    defaultMessage: 'Search for managers',
  },
  roles: {
    id: 'roles',
    defaultMessage: 'Roles',
  },
  roleName: {
    id: 'roleName',
    defaultMessage: 'Role Name',
  },
  permissions: {
    id: 'permissions',
    defaultMessage: 'Permissions',
  },
  cancelBtn: {
    id: 'cancelBtn',
    defaultMessage: 'Cancel',
  },
  doneCta: {
    id: 'doneCta',
    defaultMessage: 'Done',
  },
  errorTitle: {
    id: 'errorTitle',
    defaultMessage: 'Error',
  },
  warningTitle: {
    id: 'warningTitle',
    defaultMessage: 'Warning',
  },
  edit: {
    id: 'edit',
    defaultMessage: 'Edit',
  },
  userTypeLabel: {
    id: 'userTypeIdLabel',
    defaultMessage: 'User Type',
  },
  submitBtn: {
    id: 'submitBtn',
    defaultMessage: 'Submit',
  },
  success: {
    id: 'success',
    defaultMessage: 'Success',
  },
  successModalTitle: {
    id: 'successModalTitle',
    defaultMessage: 'Success!',
  },
  departmentHasNoRoles: {
    id: 'departmentHasNoRoles',
    defaultMessage: 'There is no role for this department',
  },
  duplicate: {
    id: 'duplicate',
    defaultMessage: 'Duplicate',
  },
  delete: {
    id: 'delete',
    defaultMessage: 'Delete',
  },
  clear: {
    id: 'clear',
    defaultMessage: 'Clear',
  },
  sessionError: {
    id: 'sessionError',
    defaultMessage: 'Your Session has Expired',
  },
  sessionErrorDesc: {
    id: 'sessionErrorDesc',
    defaultMessage: 'Please sign in again to pick up where you left off.',
  },
  signIn: {
    id: 'signIn',
    defaultMessage: 'Sign In',
  },
  signInTitle: {
    id: 'signInTitle',
    defaultMessage: 'Sign In',
  },
  unsavedDataCancelConfirmation: {
    id: 'unsavedDataCancelConfirmation',
    defaultMessage:
      'If you cancel, the data you filled will be lost. Are you sure you want to cancel it?',
  },
  confirmationTitle: {
    id: 'confirmationTitle',
    defaultMessage: 'Are you sure?',
  },
  yes: {
    id: 'yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'no',
    defaultMessage: 'No',
  },
  userDetails: {
    id: 'userDetails',
    defaultMessage: 'User Details',
  },
  users: {
    id: 'users',
    defaultMessage: 'Users',
  },
  permissionsChanged: {
    id: 'permissionsChanged',
    defaultMessage: 'Permissions Changed',
  },
  permissionsChangedMessage: {
    id: 'permissionsChangedMessage',
    defaultMessage: 'You no longer have permission to do these changes. Please click refresh to proceed.',
  },
  refresh: {
    id: 'refresh',
    defaultMessage: 'Refresh',
  },
  unknownErrorTryAgain: {
    id: 'unknownErrorTryAgain',
    defaultMessage: 'An unknown error occurred. Please try again.',
  },
  oops: {
    id: 'oops',
    defaultMessage: 'Oops!',
  },
  fixAndRetry: {
    id: 'fixAndRetry',
    defaultMessage: 'Fix and Retry',
  },
  note: {
    id: 'note',
    defaultMessage: 'Note:',
  },
  okay: {
    id: 'okay',
    defaultMessage: 'Okay',
  },
  phoneNumber: {
    id: 'phoneNumber',
    defaultMessage: 'Phone Number',
  },
  availability: {
    id: 'availability',
    defaultMessage: 'Availability',
  },
  loading: {
    id: 'loading',
    defaultMessage: 'Loading...',
  },
  somethingWentWrongTryLater: {
    id: 'somethingWentWrongTryLater',
    defaultMessage: 'Something went wrong. Please try again later.',
  },
  close: {
    id: 'close',
    defaultMessage: 'Close',
  },
  errorLabel: {
    id: 'errorLabel',
    defaultMessage: 'Error!',
  },
});
