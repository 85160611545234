import {
  createContext, useContext, FC, useReducer, useEffect,
} from 'react';
import { reducer, initialState } from './reducer';
import {
  ContextType, AppReducer, StoreProviderProps, StoreType,
} from './types';

export const Store = createContext({} as ContextType);
export const useStore = (): ContextType => useContext(Store);

export const store: StoreType = {
  dispatch: () => {
    // eslint-disable-next-line no-console
    console.error('app store is not ready');
  },
};

export const StoreProvider: FC<StoreProviderProps> = ({ children }) => {
  const [appState, dispatch] = useReducer<AppReducer>(reducer, initialState);

  useEffect(() => {
    store.dispatch = dispatch;
  }, []);

  return (
    <Store.Provider value={{ appState, dispatch }}>{children}</Store.Provider>
  );
};

export default StoreProvider;
export * from './reducer';
