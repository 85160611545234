import { Component, ReactNode } from 'react';
import { ErrorPage } from 'components/shared/ErrorPage';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  errorMessage?: string
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false } as State;
  }

  static getDerivedStateFromError(error: Error): State {
    return { hasError: true, errorMessage: error?.message };
  }

  render(): ReactNode {
    const { children } = this.props;
    const { hasError, errorMessage } = this.state;
    if (hasError) {
      return (
        <ErrorPage
          hideCta
          errorData={{
            errorMessage: errorMessage as string,
          }}
        />
      );
    }

    return children;
  }
}
