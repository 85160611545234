import { useLocation } from 'react-router-dom';

export const useQueryParams = (): Record<string, string> => {
  const params = new URLSearchParams(useLocation().search);
  return Array.from(params.entries())
    .reduce(
      (paramMap: Record<string, string>, [key, val]) => {
        paramMap[key] = val;
        return paramMap;
      },
      {},
    );
};

/**
 * // TODO:
 * 1. Implement writing to queryparams
 * 2. Move to shared library
 */
