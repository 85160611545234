import { UserManagementAuthorities } from 'enums';
import { NonEmptyArray } from '@divisionsinc/cc-shared';

export const ROLE_CREATE_PERMISSIONS: NonEmptyArray<UserManagementAuthorities> = [
  UserManagementAuthorities.CREATE_ROLE,
];

export const ROLE_DETAILS_VIEW_PERMISSIONS: NonEmptyArray<UserManagementAuthorities> = [
  UserManagementAuthorities.VIEW_ROLES,
];

export const ROLE_EDIT_PERMISSIONS: NonEmptyArray<UserManagementAuthorities> = [
  UserManagementAuthorities.EDIT_ROLE,
];

export const ServiceNames = {
  userManagement: process.env.REACT_APP_SERVICE_KEY as string,
};

export const VIEW_USER_FOR_ROLE: NonEmptyArray<UserManagementAuthorities> = [
  UserManagementAuthorities.VIEW_USERS,
  UserManagementAuthorities.VIEW_DEPARTMENT_USERS,
];

export const VIEW_ROLES: NonEmptyArray<UserManagementAuthorities> = [
  UserManagementAuthorities.VIEW_ROLES,
];

export const EDIT_STATUS: NonEmptyArray<UserManagementAuthorities> = [
  UserManagementAuthorities.EDIT_USERS_STATUS,
  UserManagementAuthorities.EDIT_DEPT_USER_STATUS,
];
