import { FC, lazy, Suspense } from 'react';
import {
  Routes as RouterRoutes,
  Route,
} from 'react-router-dom';
import { PrivateRoute } from 'components/shared/PrivateRoute';
import 'App.css';
import NotFound from 'components/pages/NotFound';
import { UserManagementAuthorities } from 'enums';
import {
  ROLE_CREATE_PERMISSIONS,
  ROLE_DETAILS_VIEW_PERMISSIONS,
  ROLE_EDIT_PERMISSIONS,
} from 'appConstants/permissions';
import { CentralSpinner } from 'components/shared/Spinner';
import { FE_URLS } from 'Routes/urls';
import { AuthoritiesFunction } from 'store/types';
import {
  Render,
  UIElements,
  useMixpanel,
  useConfig,
} from '@divisionsinc/cc-shared';
import { SendbirdDeskWidget } from '@divisionsinc/sbdesk-widget';
import useAppState from 'store/hooks';

const AddRole = lazy(() => import('components/pages/AddRole'));
const Dashboard = lazy(() => import('components/pages/Dashboard'));
const UserMangement = lazy(() => import('components/pages/UserMangement'));
const AddUser = lazy(() => import('components/pages/AddUser'));
const ViewRole = lazy(() => import('components/pages/ViewRole'));
const EditRole = lazy(() => import('components/pages/EditRole'));
const ViewUser = lazy(() => import('components/pages/ViewUser'));
const EditUser = lazy(() => import('components/pages/EditUser'));

export const hasViewUserPermission: AuthoritiesFunction = (authorities) => (
  authorities.includes(UserManagementAuthorities.VIEW_USERS)
  || authorities.includes(UserManagementAuthorities.VIEW_DEPARTMENT_USERS));

export const hasEditUserPermission: AuthoritiesFunction = (authorities) => (
  authorities.includes(UserManagementAuthorities.EDIT_USER)
  || authorities.includes(UserManagementAuthorities.EDIT_DEPT_USER));

export const hasCreateUserPermission: AuthoritiesFunction = (authorities) => (
  authorities.includes(UserManagementAuthorities.CREATE_USERS)
  || authorities.includes(UserManagementAuthorities.CREATE_DEPARTMENT_USERS));

export const Routes: FC = () => {
  const { userProfile, getProfileData } = useAppState();
  const config = useConfig();
  const mixpanel = useMixpanel();

  const isChatEnabled = () => {
    const cfg = config as unknown as Record<string, string>;
    return process.env.REACT_APP_SENDBIRD_APP_ID && (
      cfg.ocChatEnabledDepartments?.includes(userProfile?.tenant?.departmentId)
    );
  };

  return (
    <>
      <Render when={isChatEnabled()}>
        <Suspense fallback={<></>}>
          <SendbirdDeskWidget
            appId={process.env.REACT_APP_SENDBIRD_APP_ID as string}
            userId={userProfile?.sub}
            firstName={userProfile?.firstName}
            lastName={userProfile?.lastName}
            tenantName={userProfile?.tenant?.name}
            userRole={getProfileData('tenant.roleName', '')}
            departmentName={userProfile?.tenant?.departmentName}
            onActiveStateChange={(isActive) => isActive
              && mixpanel.track_ui_interaction(UIElements.CHAT, {
                element_name: UIElements.CHAT,
              })}
            accessToken=""
            placement="bottom-left"
          />
        </Suspense>
      </Render>
      <Suspense fallback={<CentralSpinner />}>
        <RouterRoutes>
          <Route
            path={FE_URLS.dashboard}
            element={(
              <PrivateRoute
                component={Dashboard}
              />
            )}
          />
          <Route
            path={FE_URLS.userManagement.addRole()}
            element={(
              <PrivateRoute
                permissions={ROLE_CREATE_PERMISSIONS}
                component={AddRole}
              />
            )}
          />
          <Route
            path={FE_URLS.userManagement.roles()}
            element={(
              <PrivateRoute
                permissions={[UserManagementAuthorities.VIEW_ROLES]}
                component={UserMangement}
              />
            )}
          />
          <Route
            path={FE_URLS.userManagement.users()}
            element={(
              <PrivateRoute
                permissions={[hasViewUserPermission]}
                component={UserMangement}
              />
            )}
          />
          <Route
            path={FE_URLS.userManagement.addUser()}
            element={(
              <PrivateRoute
                permissions={[hasCreateUserPermission]}
                component={AddUser}
              />
            )}
          />
          <Route
            path={FE_URLS.userManagement.viewRole(':roleId')}
            element={(
              <PrivateRoute
                permissions={ROLE_DETAILS_VIEW_PERMISSIONS}
                component={ViewRole}
              />
            )}
          />
          <Route
            path={FE_URLS.userManagement.usersInRole(':roleId', ':roleName')}
            element={(
              <PrivateRoute
                permissions={[hasViewUserPermission]}
                component={UserMangement}
              />
            )}
          />
          <Route
            path={FE_URLS.userManagement.editRole(':roleId')}
            element={(
              <PrivateRoute
                permissions={ROLE_EDIT_PERMISSIONS}
                component={EditRole}
              />
            )}
          />
          <Route
            path={FE_URLS.userManagement.viewUser(':userId')}
            element={(
              <PrivateRoute
                permissions={[hasViewUserPermission]}
                component={ViewUser}
              />
            )}
          />
          <Route
            path={FE_URLS.userManagement.editRole(':roleId')}
            element={(
              <PrivateRoute
                permissions={ROLE_EDIT_PERMISSIONS}
                component={EditRole}
              />
            )}
          />
          <Route
            path={FE_URLS.userManagement.editUser(':userId')}
            element={(
              <PrivateRoute
                permissions={[hasEditUserPermission]}
                component={EditUser}
              />
            )}
          />
          <Route path="*" element={NotFound} />
        </RouterRoutes>
      </Suspense>
    </>
  );
};
