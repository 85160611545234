import { UserManagementAuthorities } from 'enums';
import _get from 'lodash/get';
import _isFunction from 'lodash/isFunction';
import { NonEmptyArray, UserProfile } from '@divisionsinc/cc-shared';
import { ServiceNames } from 'appConstants/permissions';
import { useStore } from './index';
import {
  UseAppState,
  AppStateHookData,
  ServiceAuthorityPair,
  Authorities,
} from './types';

const useAppState: UseAppState = () => {
  const { appState } = useStore();
  const getProfileData: AppStateHookData['getProfileData'] = (key, defaultValue) => _get(appState, `userProfile.${key}`, defaultValue);
  const getAuthorities: AppStateHookData['getAuthorities'] = (service) => _get(appState, `userProfile.tenant.services.${service}.authorities`, []);
  const hasPermission: AppStateHookData['hasPermission'] = (service, authority) => getAuthorities(service).includes(authority);
  const hasPermissions = (permissions: NonEmptyArray<ServiceAuthorityPair>) => permissions.reduce(
    (hasAllPermissions, permission) => hasAllPermissions && hasPermission(...permission),
    true,
  );

  const hasPermissionsInRbac = (
    authorities: Authorities,
  ) => authorities.reduce(
    (hasAllPermissions, authority) => {
      if (_isFunction(authority)) {
        return hasAllPermissions && authority(getAuthorities(ServiceNames.userManagement));
      }
      return (
        hasAllPermissions
        && hasPermission(ServiceNames.userManagement, authority as UserManagementAuthorities)
      );
    },
    true,
  );

  const hasSomePermissionsInRbac = (
    authorities: NonEmptyArray<UserManagementAuthorities>,
  ) => authorities.reduce(
    (hasAllPermissions, authority) => (
      hasAllPermissions || hasPermission(ServiceNames.userManagement, authority)
    ),
    false,
  );

  const hasPermissionInRbac: AppStateHookData['hasPermissionInRbac'] = (authority) => hasPermission(ServiceNames.userManagement, authority);

  return {
    tenantId: getProfileData('tenant.id', ''),
    userTypeId: getProfileData('userType.id', ''),
    getProfileData,
    getAuthorities,
    userProfile: _get(appState, 'userProfile', {} as UserProfile),
    isUnauthenticated: _get(appState, 'isUnauthenticated', false),
    hasPermissionsChanged: _get(appState, 'hasPermissionsChanged', false),
    hasPermission,
    hasPermissionsInRbac,
    hasSomePermissionsInRbac,
    hasPermissions,
    hasPermissionInRbac,
    hasUsersPermissions: hasSomePermissionsInRbac([UserManagementAuthorities.VIEW_USERS,
      UserManagementAuthorities.VIEW_DEPARTMENT_USERS]),
    hasRolesPermissions: hasPermissionInRbac(UserManagementAuthorities.VIEW_ROLES),
  };
};

export default useAppState;
