import {
  createContext,
  useState,
  ReactNode,
  ReactElement,
} from 'react';

export type Locale=string;
export type SetLocale=(locale: string) => void;
export type LocaleContextType = [Locale, SetLocale];
type ContextType = LocaleContextType | [];

export const LocaleContext = createContext<ContextType>([]);

export const LocaleContextProvider = ({ children } : {children: ReactNode }):ReactElement => {
  const [locale, setLocale] = useState<string>('en');
  return (
    <LocaleContext.Provider value={[locale, setLocale]}>
      {children}
    </LocaleContext.Provider>
  );
};
