import URLS from 'api/urls';
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import {
  getXrequestId, ApiResponse,
} from '@divisionsinc/cc-shared';
import { UserApiData } from 'components/pages/AddUser/reducer';
import { SkillTypes } from 'components/pages/EditUser/SkillsSection/types';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { Departments } from 'types/common';

/**
 * @deprecated
 * This function is deprecated in favour of AxiosRequestConfig.params due to
 * redundancy and formatting issues.
 * @see https://axios-http.com/docs/req_config
 */
export const formatURL = (
  url: string,
  queryParams?: { [key: string]: string },
): string => {
  let query;
  if (!_isEmpty(queryParams) && queryParams) {
    // eslint-disable-next-line no-param-reassign
    Object.keys(queryParams).forEach(
      (key: string) => queryParams[key] === undefined && delete queryParams[key],
    );
    query = queryParams && new URLSearchParams(Object.entries(queryParams));
  }
  return query ? encodeURI(`${url}?&${query}`) : encodeURI(url);
};

export const callApi = async <T>(
  url: string,
  config?: AxiosRequestConfig,
): Promise<ApiResponse<T>> => {
  try {
    const headers = _get(config, 'headers', {});
    const response = await axios({
      url,
      ...config,
      headers: { ...headers },
    });
    const xRequestId = getXrequestId(response as AxiosResponse);
    return { ...response, xRequestId };
  } catch (error) {
    const xRequestId = getXrequestId((error as AxiosError)?.response as AxiosResponse);
    const errorDetails = _get(error, 'response.data', {});
    const status = _get(error, 'response.status');
    return {
      ...errorDetails, status, error: true, xRequestId,
    };
  }
};

// TODO: Pending Discussion -
// Split this object into path imports because not every api call is needed on every page.
const userManagement = {
  getRoleDetails: (roleId: string): Promise<ApiResponse> => callApi(
    formatURL(URLS.userManagement.roleDetails(roleId)),
  ),
  getDepartmentList: (
    config?: AxiosRequestConfig,
  ): Promise<ApiResponse<Departments>> => callApi<Departments>(
    formatURL(URLS.userManagement.departments),
    config,
  ),
  getUserPermissions: (config?: AxiosRequestConfig): Promise<ApiResponse> => callApi(
    formatURL(URLS.userManagement.userPermissions),
    config,
  ),
  addRole: (config: AxiosRequestConfig): Promise<ApiResponse> => callApi(
    formatURL(URLS.userManagement.roles), {
      ...config,
      method: 'POST',
    },
  ),
  getRolesList: (config?:
  AxiosRequestConfig): Promise<ApiResponse> => callApi(URLS.userManagement.rolesList, { ...config, method: 'POST' }),
  getUsersList: (config?:
  AxiosRequestConfig): Promise<ApiResponse> => callApi(URLS.userManagement.usersList, { ...config, method: 'POST' }),
  editRole: (
    roleId: string,
    config: AxiosRequestConfig,
  ): Promise<ApiResponse> => callApi(formatURL(URLS.userManagement.editRole(roleId)), {
    ...config,
    method: 'PUT',
  }),
  deleteRole: (
    roleId: string,
    config: AxiosRequestConfig = {},
  ): Promise<ApiResponse> => callApi(formatURL(URLS.userManagement.deleteRole(roleId)), {
    ...config,
    method: 'DELETE',
  }),
  getUserDetails: (userId: string): Promise<ApiResponse> => callApi(
    formatURL(URLS.userManagement.userDetails(userId)),
  ),
  getRolesByDepartment: async (departmentId: string): Promise<ApiResponse> => callApi(
    URLS.userManagement.rolesOptions, {
      params: { department_id: departmentId },
    },
  ),
  getManagersByQuery: (
    query: string,
    userId?: string,
    config?: AxiosRequestConfig,
  ): Promise<ApiResponse> => callApi(
    URLS.userManagement.getManagersByQuery(), {
      ...config,
      data: {
        userId,
        responseType: 'AUTO_FILL',
        searchQueries: [
          {
            query,
          },
        ],
      },
      method: 'POST',
    },
  ),
  getRolesListOptions: async (): Promise<ApiResponse> => callApi(
    URLS.userManagement.rolesOptions,
  ),
  editUser: (
    userId: string,
    config: AxiosRequestConfig,
  ): Promise<ApiResponse> => callApi(formatURL(URLS.userManagement.editUser(userId)), {
    ...config,
    method: 'PUT',
  }),
  createUsers: async (users: UserApiData[]): Promise<void | AxiosResponse> => {
    try {
      const response = await axios(URLS.userManagement.user, {
        method: 'POST',
        data: { users },
      });
      return response;
    } catch (error) {
      throw _get(error, 'response.data');
    }
  },
  disableUsers: (config: AxiosRequestConfig): Promise<ApiResponse> => callApi(URLS.userManagement.disableUsers(), { ...config, method: 'DELETE' }),
  getSkills: (
    skillType: SkillTypes,
    config: AxiosRequestConfig = {},
  ): Promise<ApiResponse> => callApi(URLS.userManagement.getSkills(), {
    ...config,
    params: { type: skillType, ...config.params },
  }),
  getAvailabilityStatuses: (
    config?: AxiosRequestConfig,
  ): Promise<ApiResponse> => callApi(
    URLS.userManagement.getAvailabilityStatuses(),
    config,
  ),
  saveUserPreferences: (
    serviceId: string, viewName: string, config?: AxiosRequestConfig,
  ): Promise<ApiResponse> => (
    callApi(URLS.userManagement.saveUserPreferences(serviceId, viewName), { ...config, method: 'PATCH' })
  ),
  getUserPreferences: (serviceId: string, viewName: string): Promise<ApiResponse> => (
    callApi(URLS.userManagement.getUserPreferences(serviceId, viewName))
  ),
};

const auth = {
  invalidate: (): Promise<ApiResponse> => callApi(URLS.auth.invalidate),
  refresh: (): Promise<ApiResponse> => callApi(URLS.auth.refresh),
};

const API = { userManagement, auth };

export default API;

export * from './urls';
