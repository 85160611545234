const USER_SERVICE_BASE_URL = `${process.env.REACT_APP_API_HOST}/user-service/v1`;
const REFRESH_TOKEN_BASE_URL = `${process.env.REACT_APP_API_HOST}/user/v1`;

const URLS = {
  userManagement: {
    roleDetails: (roleId: string): string => `${USER_SERVICE_BASE_URL}/roles/${roleId}`,
    editRole: (roleId: string): string => `${USER_SERVICE_BASE_URL}/roles/${roleId}`,
    deleteRole: (roleId: string): string => `${USER_SERVICE_BASE_URL}/roles/${roleId}`,
    departments: `${USER_SERVICE_BASE_URL}/departments`,
    userPermissions: `${USER_SERVICE_BASE_URL}/permissions`,
    roles: `${USER_SERVICE_BASE_URL}/role`,
    rolesOptions: `${USER_SERVICE_BASE_URL}/roles/roles-list`,
    rolesList: `${USER_SERVICE_BASE_URL}/roles/search-roles`,
    user: `${USER_SERVICE_BASE_URL}/users`,
    usersList: `${USER_SERVICE_BASE_URL}/users/search-users`,
    userDetails: (userId: string): string => `${USER_SERVICE_BASE_URL}/users/${userId}`,
    getManagersByQuery: (): string => `${USER_SERVICE_BASE_URL}/users/managers-list`,
    editUser: (userId: string): string => `${USER_SERVICE_BASE_URL}/users/${userId}`,
    disableUsers: (): string => `${USER_SERVICE_BASE_URL}/users/disable-users`,
    getSkills: (): string => `${USER_SERVICE_BASE_URL}/users/skills`,
    getAvailabilityStatuses: (): string => `${USER_SERVICE_BASE_URL}/users/enums/availability-status`,
    saveUserPreferences: (serviceId: string, viewName: string): string => `${USER_SERVICE_BASE_URL}/users/user-preferences?serviceId=${serviceId}&viewName=${viewName}`,
    getUserPreferences: (serviceId: string, viewName: string): string => `${USER_SERVICE_BASE_URL}/users/user-preferences?serviceId=${serviceId}&viewName=${viewName}`,
  },
  configs: {
    userProfile: `${USER_SERVICE_BASE_URL}/user-info`,
  },
  externals: {
    // add external urls in here
  },
  file: {
    // add file related download, upload urls in here
  },
  auth: {
    azureLogin: (): string => `${USER_SERVICE_BASE_URL}/auth/azure?client_key=${process.env.REACT_APP_CLIENT_KEY}}`,
    logout: `${REFRESH_TOKEN_BASE_URL}/logout`,
    refresh: `${REFRESH_TOKEN_BASE_URL}/token`,
    invalidate: `${REFRESH_TOKEN_BASE_URL}/auth/invalidate`,
  },
};

export default URLS;
