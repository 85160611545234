import { FC, ReactElement, useEffect } from 'react';
import {
  ErrorPage as SharedErrorPage, PrimaryButton, ApiError, useDatadog, CRANE_ERROR,
} from '@divisionsinc/cc-shared';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import _isEmpty from 'lodash/isEmpty';
import messages from './messages';

export type ErrorPageWrapperProps = {
  heading?: string;
  message?: string;
  actionButton?: ReactElement;
  className?: string;
  subMessage?: string;
  hideCta?: boolean;
  errorData?: ApiError
};

export const ErrorPage: FC<ErrorPageWrapperProps> = ({ hideCta, errorData, ...props }) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { logError } = useDatadog();

  const handleClick = () => {
    navigate(-1);
  };

  const renderCta = () => (
    hideCta ? null : (
      <PrimaryButton onClick={handleClick} size="medium" min-width={130}>
        {formatMessage(messages.goBackButton)}
      </PrimaryButton>
    )
  );

  useEffect(() => {
    if (!_isEmpty(errorData)) {
      logError(CRANE_ERROR, errorData);
    }
  }, [errorData]);

  return (
    <SharedErrorPage
      heading={formatMessage(messages.errorHeading)}
      message={formatMessage(messages.errorText)}
      subMessage={formatMessage(messages.subErrorText)}
      actionButton={renderCta() as ReactElement}
      {...props}
    />
  );
};
