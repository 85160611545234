export enum Status {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  INVITED = 'INVITED',
}

export type IDNamePair<IDType = string, TextType = string> = {
  id: IDType,
  name: TextType
};

export enum RoleFields {
  name = 'name',
  description = 'description',
  departmentId = 'departmentId',
}

export enum UserManagementAuthorities {
  CREATE_USERS = 1,
  VIEW_USERS = 2,
  EDIT_USERS_STATUS = 3,
  EDIT_USER = 4,
  CREATE_ROLE = 5,
  VIEW_ROLES = 6,
  DISABLE_ROLE = 7,
  EDIT_ROLE = 8,
  VIEW_ALL_DEPARTMENTS = 9,
  VIEW_DEPARTMENT = 10,
  VIEW_ROLES_DROPDOWN = 11,
  VIEW_DEPARTMENT_USERS = 12,
  EDIT_DEPT_USER_STATUS = 13,
  EDIT_DEPT_USER = 14,
  CREATE_DEPARTMENT_USERS = 15
}

/*
BE enum mapping
{
  "1": "CREATE_USERS",
  "2": "VIEW_USERS",
  "3": "EDIT_USERS_STATUS",
  "4": "EDIT_USER",
  "5": "CREATE_ROLE",
  "6": "VIEW_ROLES",
  "7": "DISABLE_ROLE",
  "8": "EDIT_ROLE",
  "9": "VIEW_ALL_DEPARTMENTS",
  "10": "VIEW_DEPARTMENT",
  "11": "VIEW_ROLES_DROPDOWN",
  "12": "VIEW_DEPARTMENT_USERS",
  "13": "EDIT_DEPT_USER_STATUS",
  "14": "EDIT_DEPT_USER",
  "15": "CREATE_DEPARTMENT_USERS"
}
*/

export enum InvoiceManagementAuthorities {
  CREATE_INVOICES = 'CREATE_INVOICES',
  VIEW_TINVOICES = 'VIEW_TINVOICES'
}

export enum ValidationStatus {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED'
}

export enum Organization {
  DMG = 'DMG'
}

export enum Service {
  userManagement = 'userManagement',
}

export enum PageViewType {
  USER_SERVICE_USER_LISTING = 'USER_SERVICE_USER_LISTING',
  USER_SERVICE_ROLE_LISTING = 'USER_SERVICE_ROLE_LISTING'
}
