import {
  ReactElement, FC, useEffect, Suspense, useState,
} from 'react';
import axios from 'axios';
import _get from 'lodash/get';
import {
  RouteProps,
} from 'react-router-dom';
import {
  setUserProfile,
  setIsUnauthenticated,
  useStore,
  setPermissionChanged,
} from 'store';
import { FormattedMessage } from 'react-intl';
import {
  Modal,
  useMediaBreakpoints,
  setPayloadToStorageFromParam,
  refreshToken,
  parseJwtPayload,
  getFromLocalStorage,
  reAuthenticate,
  logoutAndRedirectToLogin,
  useMixpanel,
  useConfig,
} from '@divisionsinc/cc-shared';
import NavigationWrapper from '@divisionsinc/cc-dashboard';
import { ErrorBoundary } from 'components/shared/ErrorBoundary';
import useAppState from 'store/hooks';
import messages from 'messages';
import { FE_URLS } from 'Routes/urls';
import _isEmpty from 'lodash/isEmpty';
import { Authorities } from 'store/types';
import { CentralSpinner } from 'components/shared/Spinner';
import URLS from 'api/urls';
import API from 'api';
import { CircularProgress } from '@mui/material';
import { useQueryParams } from 'hooks/useQueryParams';
import getName, { Name } from 'utils/getName';

type Props = RouteProps & {
  component: FC;
  permissions?: Authorities;
};

export const PrivateRoute = ({
  component: ChildComponent,
  permissions,
}: Props): ReactElement => {
  const { dispatch } = useStore();
  const {
    isUnauthenticated,
    hasPermissionsChanged,
    hasPermissionsInRbac,
    userProfile,
    hasUsersPermissions,
    hasRolesPermissions,
  } = useAppState();
  // TODO: Uncomment this when responsive changes are merged with experimental release
  const screenSize = useMediaBreakpoints();
  const [loading, setLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const { logout } = useQueryParams();
  const mixpanel = useMixpanel();
  const config = useConfig();

  const handleRefreshError = () => {
    dispatch(setIsUnauthenticated(true));
    axios.interceptors.request.use(
      () => {
        const controller = new AbortController();
        const cfg = {
          signal: controller.signal,
        };
        controller.abort();
        return cfg;
      },
      (error) => Promise.reject(error),
    );
  };

  useEffect(() => {
    // TODO: Get rid of axios instance

    refreshToken({
      axios,
      refreshTokenUrl: URLS.auth.refresh,
      onError: handleRefreshError,
    });
  }, []);

  useEffect(() => {
    if (logout === 'true') {
      mixpanel.reset();
      // TODO: Remove this when auto redirect to action needed jobs is removed from provider jobs
      sessionStorage.removeItem('redirectedToPage_ActionNeeded');
      logoutAndRedirectToLogin(FE_URLS.login);
      return;
    }

    axios.interceptors.response.use((response) => response,
      (error) => {
        const status = _get(error, 'response.status');
        if (status === 403) {
          dispatch(setPermissionChanged(true));
        }
        return Promise.reject(error);
      });

    const userData = setPayloadToStorageFromParam(); // setting up jwtPayload from param
    if (userData && !_isEmpty(userData)) {
      dispatch(setUserProfile(userData));
    } else if (_isEmpty(userProfile)) {
      const payload = getFromLocalStorage('jwtPayload');
      if (!payload) window.location.href = FE_URLS.login;
      const parsedPayload = parseJwtPayload(payload as string);
      if (!parsedPayload) {
        dispatch(setIsUnauthenticated(true));
      }
      return;
    }

    const data = _isEmpty(userData) ? userProfile : userData;

    setInitialized(true);

    mixpanel.identify();
    mixpanel.people.set({
      $email: data?.email,
      $name: getName(data as Name),
      $department: data?.tenant?.departmentName,
      $role: data?.tenant?.roleName,
    });
  }, [dispatch]);

  const handleConfirm = async () => {
    setLoading(true);
    mixpanel.reset();
    await API.auth.invalidate();
    setLoading(false);
    reAuthenticate(FE_URLS.login);
  };

  const renderModal = () => (
    <Modal
      content={<FormattedMessage {...messages.sessionErrorDesc} />}
      title={<FormattedMessage {...messages.sessionError} />}
      hideCloseButton
      confirmText={loading
        ? <CircularProgress size={26} color="inherit" />
        : <FormattedMessage {...messages.signInTitle} />}
      onConfirm={handleConfirm}
    />
  );

  const renderPermissionsModal = () => (
    <Modal
      content={<FormattedMessage {...messages.permissionsChangedMessage} />}
      title={<FormattedMessage {...messages.permissionsChanged} />}
      confirmText={<FormattedMessage {...messages.refresh} />}
      onConfirm={() => window.location.reload()}
      hideCloseButton
    />
  );

  /**
 * Redirects only if userProfile has been fetched and still no required permission
 */
  const waitOrRedirect = () => {
    let fallbackUrl = FE_URLS.userManagement.users();
    if (!hasUsersPermissions && !hasRolesPermissions) {
      return <div> No Access</div>;
    }
    if (!hasUsersPermissions) {
      fallbackUrl = hasRolesPermissions ? FE_URLS.userManagement.roles() : FE_URLS.dashboard;
    }
    if (!_isEmpty(userProfile)) {
      window.location.href = fallbackUrl;
    } else {
      return <div> Access denied </div>;
    }

    return <CentralSpinner />;
  };
  return (
    <>
      {initialized ? (
        <NavigationWrapper drawerVariant={screenSize.mdDown ? 'temporary' : 'permanent'} logoutRedirectUri={URLS.auth.logout} config={config}>
          <ErrorBoundary>
            {!permissions || (permissions && hasPermissionsInRbac(permissions)) ? (
              <Suspense fallback={<CentralSpinner />}>
                <ChildComponent />
                {hasPermissionsChanged && renderPermissionsModal()}
              </Suspense>
            ) : waitOrRedirect()}
            {isUnauthenticated && renderModal()}
          </ErrorBoundary>
        </NavigationWrapper>
      ) : <CentralSpinner />}
    </>
  );
};

/**
 * // TODO: Write Test cases
 * Permission supplied -> not fetched -> Blank
 * Permission supplied -> fetched -> permission available -> render
 * Permission supplied -> fetched -> permission not avaliable -> redirect
 * Permission not supplied -> render
 */
