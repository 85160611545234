import { defineMessages } from 'react-intl';

export default defineMessages({
  errorHeading: {
    id: 'errorHeading',
    defaultMessage: 'Sorry, Unexpected Error',
  },
  errorText: {
    id: 'errorText',
    defaultMessage: 'An error occured and your request couldn’t be completed.',
  },
  subErrorText: {
    id: 'subErrorText',
    defaultMessage: 'Please try again later.',
  },
  homeButton: {
    id: 'homeButton',
    defaultMessage: 'Home',
  },
  goBackButton: {
    id: 'goBackButton',
    defaultMessage: 'Go Back',
  },
  loginButton: {
    id: 'loginButton',
    defaultMessage: 'Login',
  },
});
