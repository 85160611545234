import { ReactElement } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const messages = defineMessages({
  notFound: {
    id: 'notFound',
    defaultMessage: 'Not Found',
  },
});

const NotFound = (): ReactElement => (
  <Grid
    container
    alignItems="center"
    justifyContent="center"
    style={{ height: '100vh' }}
  >
    <Typography variant="h2">
      <FormattedMessage {...messages.notFound} />
    </Typography>
  </Grid>
);

export default NotFound;
