import { UserManagementAuthorities } from 'enums';
import { Dispatch, ReactChild } from 'react';
import {
  NonEmptyArray, Reducer, ActionWithPayload, UserProfile,
} from '@divisionsinc/cc-shared';

export enum ActionTypes {
  SET_PROFILE_DATA = 'SET_PROFILE_DATA',
  SET_IS_UNAUTHORIZED = 'SET_IS_UNAUTHORIZED',
  SET_HAS_PERMISSIONS_CHANGED = 'SET_HAS_PERMISSIONS_CHANGED',
}

export type State = {
  userProfile: UserProfile;
  hasPermissionsChanged: boolean;
  isUnauthenticated: boolean;

};

type Value = UserProfile | boolean;

export type AppReducer = Reducer<State, ActionTypes, Value>;

export type Action = ActionWithPayload<ActionTypes, Value>;

export type AppDispatch = Dispatch<Action>;

export type ContextType = { appState: State, dispatch: AppDispatch };

export type StoreProviderProps = {
  children: ReactChild;
};

export type ServiceAuthorityPair = [
  service: string,
  authority: UserManagementAuthorities
];

export type AuthoritiesFunction = (authorities: NonEmptyArray<UserManagementAuthorities>)
=> boolean;
export type Authorities = NonEmptyArray<UserManagementAuthorities | AuthoritiesFunction>;

export type AppStateHookData = {
  tenantId: string;
  userTypeId: string;
  userProfile: UserProfile;
  isUnauthenticated: boolean;
  hasPermissionsChanged: boolean;
  getProfileData: <T>(key: string, defaultValue: T) => T,
  getAuthorities: (service: string) => NonEmptyArray<UserManagementAuthorities>,
  hasPermission: (...permission: ServiceAuthorityPair) => boolean,
  hasPermissions: (permission: NonEmptyArray<ServiceAuthorityPair>) => boolean,
  hasPermissionInRbac: (authority: UserManagementAuthorities) => boolean,
  hasPermissionsInRbac: (authority: Authorities) => boolean,
  hasSomePermissionsInRbac: (authority: NonEmptyArray<UserManagementAuthorities>) => boolean,
  hasRolesPermissions: boolean;
  hasUsersPermissions: boolean;
};

export type UseAppState = () => AppStateHookData;

export type StoreType = {
  dispatch: AppDispatch
};
