import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import axios from 'axios';
import { StoreProvider } from 'store';
import { ConfigContextProvider, trackingHeadersInterceptor, DatadogProvider } from '@divisionsinc/cc-shared';
import { LocaleContextProvider } from './LocaleContext';
import App from './App';

axios.defaults.withCredentials = true;

// add headers to requests
axios.interceptors.request.use(
  trackingHeadersInterceptor(),
);

ReactDOM.render(
  <StrictMode>
    <StoreProvider>
      <LocaleContextProvider>
        <ConfigContextProvider>
          <DatadogProvider
            token={process.env.REACT_APP_DATA_DOG_CLIENT_TOKEN}
            applicationId={process.env.REACT_APP_DATA_DOG_APP_ID as string}
            serviceName="control-center-oc-view"
          >
            <App />
          </DatadogProvider>
        </ConfigContextProvider>
      </LocaleContextProvider>
    </StoreProvider>
  </StrictMode>,
  document.getElementById('root'),
);
