export type Name = { firstName: string; lastName: string };
type GetName = (name: Name) => string;

const getName: GetName = (name: Name) => {
  if (!name) return '';
  const { firstName, lastName } = name;
  if (!firstName && !lastName) return '';
  return `${firstName || ''} ${lastName || ''}`;
};

export default getName;
